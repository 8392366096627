import React, { ReactNode } from 'react'

import { Alert } from '../types'
import AlertsToaster from '../components/alerts/floating-alert'

/**
 * Provider for global notifications, alerts, errors.
 * They will be shown by a Floating Toast component.
 */

const initialState : { alerts: Alert[], addAlert: any } = { alerts: [], addAlert: () => {} } 
const AlertsContext = React.createContext(initialState)

const AlertsProvider = ({ children }: { children: ReactNode }) => {
    const [alerts, setAlerts] = React.useState(initialState.alerts)
    const addAlert = React.useCallback((alert: Alert) => {
        const newAlert = { ...alert, id: Math.random() }
        const newAlerts: Alert[] = [...alerts, newAlert]
        setAlerts(newAlerts)
        // delete alert after 5 seconds
        setTimeout(() => {
            const index = newAlerts.findIndex((a: Alert) => a.id === newAlert.id)
            newAlerts.splice(index, 1)
            setAlerts([...newAlerts])
        }, 3000)
    }, [alerts])

    const alertsContextValue = React.useMemo(() => ({ alerts, addAlert }), [alerts, addAlert])
    return (
        <AlertsContext.Provider value={alertsContextValue}>
            {children}
            <AlertsToaster />
        </AlertsContext.Provider>
    )
}

export const useAlerts = () => React.useContext(AlertsContext)
export default AlertsProvider