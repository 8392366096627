import React from 'react'
import './styles.scss'

const IconButton = React.forwardRef(({ icon, width, ...rest }: { icon: string, [x:string]: any; }, ref: any) => {
  return (
    <button className='btn btn-link icon-button m-0 p-0' ref={ref} {...rest}>
      {icon}
    </button>
  )
})

export default IconButton