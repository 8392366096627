import useSWR from 'swr'

import { getInvoiceHistory } from '../../api'
import Spinner from '../ui/spinner'
import List from '../ui/list'
import * as dateLib from '../../lib/dates'

type Props = {
  invoiceId: string 
}

const InvoiceHistory: React.FC<Props> = ({ invoiceId }) => {
  const { data: historyResponse, isLoading } = useSWR(`/api/invoices/${invoiceId}/history`, getInvoiceHistory)
  
  const headers = [
    { label: 'Activity' }, 
    { label: 'Date' },
  ]
  const rows = historyResponse?.data && historyResponse?.data.map((item: any) => {
    return [
      <div>{item.message}</div>,
      <small>{dateLib.formatDateShort(item.createdDate)}</small>
    ]
  })

  return (
    <div>
      <h5>Activity</h5>
      {isLoading ? (<Spinner />) : (
        <div className='card p-2'>
          <List headers={headers} rows={rows} hideHeaders />
        </div>
      )}
    </div>
  )
}

export default InvoiceHistory