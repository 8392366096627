import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import Footer from './footer'
import Hero from './hero'
import { useAuth } from '../../context/auth'

const LandingPage = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  React.useEffect(() => {
    if(user) {
      navigate('/dashboard')
    }
  }, [user])
  return (
    <div>
      <Hero />
      <Footer />
    </div>
  )
}

export default LandingPage
