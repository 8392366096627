import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import './theme/reset.css'
import './theme/customBootstrap.scss'
import App from './App'
import AlertsProvider from './context/alerts'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <AlertsProvider>
      <BrowserRouter>
          <App /> 
      </BrowserRouter>
    </AlertsProvider>
  </React.StrictMode>
)