import React from 'react'

import Logo from '../../../static/images/logo.svg'

const TinyInvoiceLogo = ({ width = 150 }) => {
  return (
    <img src={Logo} width={width} alt='Tiny Invoice' />
  )
}

export default TinyInvoiceLogo
