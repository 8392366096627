import React from 'react'
import NavBar from '../navbar'
import { Link } from 'react-router-dom'

import ScreenImage from '../../../static/images/screen.png'
import CheckIcon from '../../../static/images/check.svg'
import { ReactComponent as SimpleTextFloating } from '../../../static/images/simple-text.svg'
type Props = { children: string }
const Feature: React.FC<Props> = ({ children }) => (<div className='d-inline-flex align-items-center px-2'><img className='me-1' width='30' src={CheckIcon} alt='check icon invoice' />{children}</div>)
const Hero = () => {
  return (
    <div>
      <NavBar />
      <main className='container col-xxl-8 py-5'>
        <div className='text-center'>
          <h1 className='display-1 mt-2 col-md-10 mx-auto'>
            Create, Send & Track Your Freelance Invoices Effortlessly
          </h1>
          <div className='my-4'>
            <Feature>Send Invoices as PDFs</Feature>
            <Feature>Multiple Currencies</Feature>
            <Feature>Automated Due Email Reminders</Feature>
          </div>
          <Link to='/login' className='btn btn-lg btn-primary mt-4'>Try It Now!</Link>
          <div className='mt-4 text-secondary'><small><b className='bg-info text-white py-1 px-2 rounded me-2'>FREE</b><b className='fw-light'>for All 2024 Signups!</b></small></div>

          <div className='mt-5 ms-auto text-end'><SimpleTextFloating className='col-6 col-md-3' /></div>
          <div className='w-md-75 mx-auto'>
            <img src={ScreenImage} className='border-5 border-end border-bottom border-dark rounded img-fluid' alt='Easy Invoice Creation' />
          </div>
        </div>
      </main>
    </div>
  )
}

export default Hero
