import * as logger from '../lib/logger.ts'

const fetcher = (url, options) => {
  return fetch(url, {
    headers: {
      'content-type': 'application/json',
    },
    credentials: 'include',
    ...options,
  }).then(async (res) => {
    let payload
    try {
      if (res.status === 204) return null // 204 does not have body
      if(res.status === 429) {
        return Promise.reject(new Error('Too many requests, please try again later'))
      }
      if(res.status === 404) {
        return Promise.reject(new Error('API not found'))
      }
      if(res.status === 403) {
        payload = await res.json()
        return Promise.reject(new Error(payload.error))
      }
      if(res.status === 500) {
        const error = await res.json()
        return Promise.reject(new Error(error.message))
      }
      payload = await res.json()
    } catch (e) {
      /* noop */
    }

    if (res.ok) {
      logger.debug('-----')
      logger.debug(url)
      logger.debug(payload)
      logger.debug('-----')
      return payload
    } else {
      return Promise.reject(payload?.error || new Error('Something went wrong'))
    }
  })
}

export default fetcher
