/**
 * Button that looks like a link
 */
import React from 'react'
import './styles.scss'

type Props = {
  icon?: any
  children: React.ReactNode
  [x: string]: any
}

const ButtonLink: React.FC<Props> = ({ icon, children, ...rest }) => {
  return (
    <button {...rest} className='btn btn-link button-link'>
      {icon && React.cloneElement(icon, { width: 40, height: 40 })}
      {children}
    </button>
  )
}

export default ButtonLink