import { useLocation } from 'react-router-dom'

import { useAuth } from '../../context/auth'
import GoogleLoginButton from '../../components/ui/button/google/GoogleLogin'
import TinyInvoiceLogo from '../../components/ui/logo'
import './styles.scss'

const LoginPage = () => {
  const { signin, user } = useAuth()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/dashboard'
  
  if(user) {
    window.open(from, '_self')
  }

  return (
    <div className='login-container'>
      <div>
        <TinyInvoiceLogo width={300} />
      </div>
      <GoogleLoginButton onClick={signin} />
    </div>
  )
}

export default LoginPage