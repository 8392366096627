import useSWR from 'swr'

import List from '../ui/list'
import { getUsers } from '../../api'
import { useNavigate } from 'react-router-dom'
import useQuery from '../../context/useQuery'

const UsersList = () => {
  const { data: users, isLoading } = useSWR('/api/users', getUsers)
  const navigate = useNavigate()
  const params = useQuery()

  const headers = [
    { label: 'Email' },
    { label: 'Invoices' },
    { label: 'Total $' },
    { label: 'Latest Invoice' },
  ]
  const rows: Array<any[]> = []

  if(isLoading) return (<div>Loading...</div>)
  if(users && users.data) {
    users.data
    .filter((user: any) => Number(user.invoiceCount) > Number(params.get('invCount')))
    .forEach((user: any) => {
      rows.push([user.email, user.invoiceCount, user.totalInvoiceAmount, user.latestInvoiceDate])
    })
  }

  const minAmountInvoices = (amount: number) => users?.data.filter((user: any) => user.invoiceCount > amount).length

  return (
    <>
      <p><b>Total Users:</b> {users?.data.length}</p>
      <p><b>Total Users with more than 5 invoices:</b> {minAmountInvoices(5)}</p>
      <p><b>Total Users with more than 10 invoices:</b> {minAmountInvoices(10)}</p>
      <label>Filter by amount of invoices:</label>
      <select onChange={(e) => navigate(`/admin?invCount=${e.target.value}`)}>
        <option value={0}>+0</option>
        <option value={5}>+5</option>
        <option value={10}>+10</option>
      </select>
      <List headers={headers} rows={rows} />
    </>
  )
}

export default UsersList
