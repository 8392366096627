import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { useNavigate, useParams } from 'react-router-dom'

import InvoiceForm from '../../components/invoices/form'
import { getInvoiceById, saveOrUpdateInvoice, removeInvoice } from '../../api'
import { Invoice } from '../../types'
import { useAlerts } from '../../context/alerts'
import InvoiceHistory from '../../components/invoices/history'
import Spinner from '../../components/ui/spinner'

const defaultValues: Invoice = {
  code: '',
  clientId: '',
  total: '',
  items: [{ description: '', amount: '' }, { description: '', amount: '' }],
  issueDate: new Date(),
  dueDate: new Date(new Date().setDate(new Date().getDate() + 7)),
  locked: false,
}

const EditInvoicePage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { addAlert } = useAlerts() 
  const { data, isLoading, error, mutate } = useSWR(`/api/invoices/${id}`, getInvoiceById)
  const { trigger, isMutating } = useSWRMutation('/api/invoices', saveOrUpdateInvoice)
  const { trigger: triggerRemoveInvoice, isMutating: isRemoving } = useSWRMutation(`/api/invoices/${id}`, removeInvoice)

  if(data?.isDeleted) {
    return <div>This invoice has been deleted</div>
  }

  const handleRemoveInvoice = async () => {
    if(window.confirm('Are you sure you want to delete this invoice?')){
      await triggerRemoveInvoice()
      addAlert({ type: 'info', message: 'Invoice deleted' })
      navigate('/dashboard', { replace: true })
    }
  }

  if(error){
    return <div>Error ocurred.</div>
  }
  if(id === undefined) return <div>Invalid invoice id</div>
  if(isLoading) return <Spinner />

  const handleSubmit = async(data: any) => {
    await trigger(data)
    await mutate()
  }

  return (
    <>
      {data.locked && (
        <p>
          This invoice has been sent to your client, you can only update the <b>Status</b> field.
        </p>
      )}
      <InvoiceForm 
        defaultValues={data || defaultValues} 
        triggerSaveInvoice={handleSubmit} 
        triggerRemoveInvoice={handleRemoveInvoice}
        isLoading={isMutating || isRemoving} 
      />
      <div className='mt-5 pb-5'>
        <InvoiceHistory invoiceId={id} />
      </div>
    </>
  )
}

export default EditInvoicePage