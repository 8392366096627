import Tooltip from '../tooltip'
import './styles.scss'

type Props = {
  label?: string
  size?: 'sm' | 'md' | 'lg'
  tooltip?: string
  [x: string]: any
}

const Input: React.FC<Props> = ({ label, size='md', tooltip, ...rest }) => {
  return (
    <>
      {label && (
        <label htmlFor={rest.id} className='form-label bold-label'>
          {label}
          <Tooltip message={tooltip} />
        </label>
      )}
      <input className={`form-control form-control-${size}`} {...rest} />
    </>
  )
}

export default Input