import React from 'react'
import { useLocation } from 'react-router-dom'

import { useAuth } from '../../../../context/auth'
import useScreenSize from '../../../../context/screenSize'
import IconButton from '../../button/icon'
import TinyInvoiceLogo from '../../logo'
import NavLink from '../../navLink'
import { ReactComponent as MenuBurgerLogo } from '../../../../static/images/menu-burger.svg'
import { ReactComponent as CloseIcon } from '../../../../static/images/close.svg'
import { ReactComponent as DashboardIcon} from '../../../../static/images/dashboard.svg'
import { ReactComponent as LogoutIcon } from '../../../../static/images/logout.svg'
import { ReactComponent as ClientIcon } from '../../../../static/images/client.svg'
import { ReactComponent as SettingsIcon } from '../../../../static/images/settings.svg'
import ButtonLink from '../../button/link'
import './styles.scss'

/**
 * In mobile view, the header content is displayed alongside the menu icon.
 */
type Props = { hideMenus?: boolean, headerContent: React.ReactElement }

const SideBar: React.FC<Props> = ({ hideMenus, headerContent }) => {
  const location = useLocation()
  React.useEffect(() => {
    document.body.classList.remove('no-scroll')
    setIsOpen(false)
  }, [location])
  const [isOpen, setIsOpen] = React.useState(false)
  const { signout } = useAuth()
  const { isMobile } = useScreenSize()

  const toggleSideBar = () => {
    if(!isOpen) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
     }
    setIsOpen(!isOpen)
  }

  // TODO: this 768 should be same number as $mobile-break sass variable of _base.scss file
  if(!isOpen && isMobile) {
    return (
      <div className='row align-items-center fixed-top closed-sidebar-container py-3 px-4'>
        <div className='col-1'><IconButton onClick={toggleSideBar} icon={<MenuBurgerLogo width={30} height={30} />} /></div>
        <div className='col-11 d-flex justify-content-between px-4'>{headerContent}</div>
      </div>
    )
  }
  return (
    <div className='sidebar-container vstack gap-2'>
      <div className='d-flex align-items-start justify-content-between'>
        <TinyInvoiceLogo />
        { isMobile && <IconButton icon={<CloseIcon width={30} height={30} />} onClick={toggleSideBar} />}
      </div>
      <div className='vstack gy-5 mt-4'>
        {hideMenus ? null : (
          <>
            <div className='mt-2'><NavLink icon={<DashboardIcon />} to='/dashboard'>Invoices</NavLink></div>
            <div className='mt-2'><NavLink icon={<SettingsIcon />} to='/profile'>Profile</NavLink></div>
            <div className='mt-2'><NavLink icon={<ClientIcon />} to='/clients'>Clients</NavLink></div>
          </>
        )}
        <div className='mt-auto'><ButtonLink onClick={signout} icon={<LogoutIcon  />}>Logout</ButtonLink></div>
      </div>
    </div>
  )
}

export default SideBar
