import React from 'react'
import { NavLink } from 'react-router-dom'

import './styles.scss'

type Props = {
  to: string
  children: string | React.ReactNode
  icon?: any
}

const ThemedNavLink: React.FC<Props> = ({ to, icon, children }) => {
  return (
    <NavLink to={to} className='nav-link'>
      {React.cloneElement(icon, { width: 30, height: 30 })}
      {children}
    </NavLink>
  )
}

export default ThemedNavLink
