import ClientsList from '../../components/clients/list'

const ClientsPage = () => {
  return (
    <div className='col-lg-9'>
      <ClientsList />
    </div>
  )
}

export default ClientsPage