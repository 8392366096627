import React from 'react'
import { Link } from 'react-router-dom'

import TinyInvoiceLogo from '../../../components/ui/logo'

const NavBar = () => {
  return (
    <nav className='navbar navbar-expand-md container col-xxl-8'>
      <div className='container d-flex justify-content-between'>
        <a href='/' className='navbar-brand col-4 col-md-3 col-lg-2'><TinyInvoiceLogo /></a>
        <div>
          <Link to='/login' className='btn btn-outline-primary me-3' type='button'>Login</Link>
          <Link to='/login' className='btn btn-primary' type='button'>Signup</Link>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
