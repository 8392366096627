import React from 'react'
import Tooltip from '../tooltip'

type Props = {
  label: string
  rows: number
  tooltip?: string,
  [x: string]: any
}

const TextArea: React.FC<Props> = ({ label, tooltip, ...rest}) => {
  return (
    <>
      {label && <label htmlFor={rest.id} className='form-label bold-label'>{label}</label>}
      <Tooltip message={tooltip} />
      <textarea className='form-control' {...rest} />
    </>
  )
}

export default TextArea