import React from 'react'
import { useNavigate } from 'react-router-dom'

import BusinessDetailsForm from '../../components/business/form'
import { useAlerts } from '../../context/alerts'
import useQuery from '../../context/useQuery'
import RemindersForm from '../../components/reminders/form'

const UserProfile = () => {
  const query = useQuery()
  const { addAlert } = useAlerts()
  const navigate = useNavigate()

  React.useEffect(() => {
    if(query.get('warning')) {
      addAlert({ type: 'warning', message: query.get('warning') })
    }
  }, [addAlert, query])

  const handleCancel = () => {
    // navigate to previous page
    navigate(-1)
  }

  return (
    <div className=''>
      <div className='row'>
        <div className='card col-lg-8 mx-auto d-block'>
          <div className='card-body'>
            <h5 className='card-title mb-4'>Business Details</h5>
            <BusinessDetailsForm 
              autofocusField={query.get('autofocus') || ''} 
              redirectAfterSaveTo={query.get('redirectAfterSaveTo') || undefined} 
              onCancel={handleCancel}
            />
          </div>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='card col-lg-8 mx-auto d-block'>
          <div className='card-body'>
            <h5 className='card-title mb-1'>Email Reminders</h5>
            <p className='text-muted mb-4'>Choose when to send out email reminders about overdue invoices to your clients. You can have a maximum of 2 reminders active at the same time.</p>
            <RemindersForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfile