import useSWR from 'swr'
import { useNavigate, useParams } from 'react-router-dom'

import { getClientById } from '../../api'
import ClientForm from '../../components/clients/form'
import { parseClientToForm } from '../../lib/parsers'
import ClientBalance from '../../components/clients/balance'

const EditClientPage = () => {
  const { id } = useParams()
  const { data, mutate, error, isLoading } = useSWR(`/api/clients/${id}`, getClientById)
  const navigate = useNavigate()

  if(!data || error){
    return <div>Error ocurred.</div>
  }
  if(id === undefined) return <div>Client id is required</div>
  if(isLoading) return <div>Loading...</div>

  const onSave = async () => {
    mutate()
    return navigate(-1)
  }

  const onCancel  = () => {
    return navigate(-1)
  }

  return (
    <div className='col-lg-6'>
      <ClientForm
        onCancel={onCancel}
        defaultValues={parseClientToForm(data)}
        onSave={onSave}
      />
      <div className='mt-5' />
      <ClientBalance />
    </div>
  )
}

export default EditClientPage
