import './styles.scss'

import GoogleLogo from '../../../../static/images/google-icon.svg'

const GoogleLoginButton = (props: any) => {
  return (
    <button id='googleLogin' {...props}>
      <img src={GoogleLogo} className='icon' alt='google login' />
      <span className='buttonText'>Sign in with Google</span>
    </button>
  )
}

export default GoogleLoginButton