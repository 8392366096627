import React from 'react'
import './styles.scss'

/**
 * Shows a question mark icon 
 * when hovered over shows a tooltip message in a bubble.
 */
type Props = {
  message?: string
}
const Tooltip: React.FC<Props> = ({ message }) => {
  if(!message) return null

  return (
    <div className='question-bubble d-inline-flex'>
      ?
      <span className='tooltip-text bg-white'>{message}</span>
    </div>
  )
}

export default Tooltip