import { Client, TClientForm } from '../types'

export const parseClientToForm = (client: Client): TClientForm => {
  return { 
    _id: client?._id?.toString(), 
    'client.tradingName': client.tradingName, 
    'client.billingAddress': client.billingAddress,
    'client.name': client.name,
    'client.lastName': client.lastName,
    'client.email': client.email 
  }
}