import React from 'react'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { Navigate } from 'react-router-dom'

import { Invoice } from '../../types'
import { useAuth } from '../../context/auth'
import Alert from '../../components/alerts/inline-alert'
import InvoiceForm from '../../components/invoices/form'
import { saveOrUpdateInvoice, getClients, getNextCode, getClonedInvoice } from '../../api'
import useQuery from '../../context/useQuery'
import Spinner from '../../components/ui/spinner'
import NewClient from '../clients/new'

const EMPTY_ITEM = { description: '', amount: '', taxPercentage: '' }

const EMPTY_VALUES: Invoice = {
  code: undefined,
  clientId: '',
  items: [{...EMPTY_ITEM}, {...EMPTY_ITEM}],
  issueDate: new Date(),
  dueDate: new Date(new Date().setDate(new Date().getDate() + 7)),
  locked: false
}

const NewInvoice = () => {
  const { user } = useAuth()
  EMPTY_ITEM.taxPercentage = user?.businessDetails?.tax?.percentage

  const query = useQuery()

  const { trigger, isMutating } = useSWRMutation('/api/invoices', saveOrUpdateInvoice)
  const { data: clients, isLoading: isLoadingClients } = useSWR('/api/clients', getClients)
  const { data: clonedInvoice, isLoading: isLoadingCloned } = useSWR(query.get('cloneId') ? `/api/invoices/clone/${query.get('cloneId')}` : null, getClonedInvoice)
  const [defaultValues, setDefaultValues] = React.useState({ ...EMPTY_VALUES, businessDetails: user.businessDetails })
  const { data: nextCode } = useSWR('/api/invoices/next-code', getNextCode)
  
  React.useEffect(() => {
    // Default Currency fetched
    const EMPTY_ITEM_WITH_TAX = {...EMPTY_ITEM, taxPercentage: user?.businessDetails?.tax?.percentage}
    const newValues = { 
      items: [{...EMPTY_ITEM_WITH_TAX}, {...EMPTY_ITEM_WITH_TAX}], 
      code: nextCode?.data,
    }
    setDefaultValues(v => ({...v, ...newValues}))
  }, [nextCode, user])

  let defaultClientSelected = query.get('clientId') || ''
  if(defaultClientSelected === '' && clients && clients.length === 1) {
    defaultClientSelected = clients[0]._id
  }

  const getDefaultValues = React.useCallback(() => {
    return clonedInvoice?.data ? { ...EMPTY_VALUES, ...clonedInvoice?.data } : { ...defaultValues, clientId: defaultClientSelected }
  }, [clonedInvoice, defaultValues, defaultClientSelected])

  if(!user.businessDetails) {
    // user did not complete business details redirect to profile page.
    const profileUrl = `/profile?autofocus=businessDetails.name`
    return (
      <Navigate  to={profileUrl} />
    )
  }

  if(clients && clients.length === 0) {
    return (
      <>
        <div className='col-lg-6'>
          <Alert message={`Please add at least 1 client, before creating an invoice`} />
          <NewClient redirectAfterSaveTo='/invoices/new' />
        </div>
      </>
    )
  } 

  if(isLoadingCloned || isLoadingClients || !defaultValues.code) {
    return <Spinner />
  }

  const handleSubmit = (data: any) => {
    return trigger(data)
  }
  return (
    <InvoiceForm 
      defaultValues={getDefaultValues()}
      triggerSaveInvoice={handleSubmit} 
      isLoading={isMutating} 
    />
  )
}

export default NewInvoice