import React from 'react'
import Spinner from '../spinner'

type Props = {
  total: number // total number of steps
  current: number // current step number
  children: React.ReactNode
  heading?: string
  subHeading: string
  loading?: boolean
}
const Step: React.FC<Props> = ({ total, current, children, heading, subHeading, loading }) => {
  if(loading) {
    return <Spinner />
  }
  return (
    <div>
      <h6 className='pb-4'>Step {current} of {total}</h6>
      {heading && <h2 className='pb-3'>{heading}</h2>}
      <p className='lead pb-3'><i>{subHeading}</i></p>
      {children}
    </div>
  )
}

export default Step