import { useNavigate } from 'react-router-dom'

import { ReactComponent as LeftArrow } from '../../../../static/images/left-arrow.svg'
import IconButton from '../icon'

type Props = { backTo?: string }
const GoBackButton: React.FC<Props> = ({ backTo }) => {
  const navigate = useNavigate()
  const handleClick = () => {
    if(backTo) {
      navigate(backTo)
    } else {
      navigate(-1)
    }
  }

  return (
    <IconButton icon={<LeftArrow width={40} height={40} />} onClick={handleClick} />
  )
}

export default GoBackButton