import React from 'react'
import { NavLink } from 'react-router-dom'

type Props = {
  message: string
  actionLink?: string
  actionLabel?: string
}
const Alert: React.FC<Props> = ({ message, actionLink, actionLabel }) => {
  return (
    <div>
      <div className='badge bg-warning text-dark mb-2 p-2'>{message}</div>
      {actionLabel && actionLink && <NavLink to={actionLink}>{actionLabel}</NavLink>}
    </div>
  )
}

export default Alert