import React from 'react'

import './checkboxStyles.scss'

type Props = {
  label: string
  size?: 'md' | 'sm' | 'lg' 
  [x: string]: any
}

const Checkbox: React.FC<Props> = ({ label, size = 'md', ...rest }) => {
  return (
    <div className={`form-check form-control-${size} themed-checkbox-group`}>
      <input className='form-check-input' type='checkbox' {...rest} />
      <label className='form-check-label' htmlFor={rest.id}>
        {label}
      </label>
    </div>
  )
}

export default Checkbox