import React from 'react'

import { useAlerts } from '../../context/alerts'
import { Alert } from '../../types'
import './styles.scss'

const FloatingAlerts = () => {  
  const { alerts } = useAlerts()
  if(alerts.length === 0) return null

  return (
    <div className='toaster-floating-container'>
      {
        alerts.map((alert: Alert) => {
          return <Toaster key={alert.id} data={alert} />
        })
      }
    </div>
  )
}


type ToasterProps = {
  data: Alert
}

const Toaster: React.FC<ToasterProps> = ({ data }) => {
  const [show, setShow] = React.useState<boolean>(true)
  const hideAlert = () => {
    setShow(false)
  }

  let alertTypeClass = 'alert-primary'
          
  switch(data.type) {
    case 'error':
      alertTypeClass = 'alert-danger'
      break
    case 'warning':
      alertTypeClass = 'alert-warning'
      break
    case 'info':
    default:
      alertTypeClass = 'alert-primary'
      break
  }

  return (
    <div className={`alert fade ${show ? 'show' : ''} ${alertTypeClass} d-flex align-items-center justify-content-sm-between`} role='alert'>
      <div className='alert-message-container'><strong>{data.message}</strong></div>
      <button type='button' onClick={hideAlert} className='btn-close' data-bs-dismiss='alert' aria-label='Close'></button>
    </div>
  )
}

export default FloatingAlerts