import BusinessDetailsForm from '../../components/business/form'
import Step from '../../components/ui/stepper/step'

const Step1Profile = () => {
  return (
    <div className=''>
      <Step subHeading='Tell us about your business.' current={1} total={3}>
        <BusinessDetailsForm 
          redirectAfterSaveTo='/onboard-step-2' 
          autofocusField='businessDetails.name'
          silent
          saveLabel='Next'
          cancelLabel='Back'
          showFakeInvoice
        />
      </Step>
    </div>
  )
}

export default Step1Profile