import React from 'react'

type Props = {
  businessName?: string
  businessEmail?: string
  businessAddress?: string
  clientName?: string,
  clientEmail?: string,
  clientAddress?: string,
  paymentInstructions?: string
}
const FakeInvoice:React.FC<Props> = ({ paymentInstructions, clientName, clientEmail, clientAddress, businessName, businessEmail, businessAddress }) => {
  const PLACEHOLDER = <span className="placeholder bg-dark-subtle w-100" />
  return (
    <div className="col ms-4 vh-80 d-none d-lg-flex d-none bg-primary-subtle align-items-center justify-content-center">
      <div className='p-3 m-5 w-100 bg-light-subtle'>
        <div className='row'>
          <div className='col-5'>
            <h2>INVOICE</h2>
            <div className='mt-2'><small className='fw-medium'>Bill to: </small></div>
            <p className='fs-5 m-0'>{clientName || PLACEHOLDER}</p>
            <div><small className='fs-8 fw-medium'>{clientEmail || PLACEHOLDER}</small></div>
            <div className='w-75 fs-8 fw-light'>{clientAddress || PLACEHOLDER}</div>
          </div>
          <div className='col-5 ms-auto text-end'>
            <p className='fs-3 text-truncate'>{businessName || PLACEHOLDER}</p>
            <div className='text-truncate'><small className='fs-8 fw-medium'>{businessEmail || PLACEHOLDER}</small></div>
            <div className='ms-auto fw-light'><small className='fs-8'>{businessAddress || PLACEHOLDER}</small></div>
          </div>
        </div>

        <div className='row mt-3'>
          <div className='col-9'>{PLACEHOLDER}</div>
          <div className='col-3'>{PLACEHOLDER}</div>
        </div>
        <div className='row'>
          <div className='col-9'>{PLACEHOLDER}</div>
          <div className='col-3'>{PLACEHOLDER}</div>
        </div>
        <div className='row'>
          <div className='col-9'>{PLACEHOLDER}</div>
          <div className='col-3'>{PLACEHOLDER}</div>
        </div>
        <div className='mt-5 mb-2'><small className='fw-medium'>Payment Instructions</small></div>
        <div className='row card m-1 py-2 text-nowrap fw-light'>
          <p style={{ whiteSpace: 'pre-line' }}>{paymentInstructions || <>
            {PLACEHOLDER}
            {PLACEHOLDER}
            {PLACEHOLDER}
          </>}</p>
        </div>
      </div>
    </div>
  )
}

export default FakeInvoice
