import useSWR from 'swr'

import { getClients } from '../../api'
import { useAlerts } from '../../context/alerts'
import SelectBox from '../ui/selectBox'
import { NavLink } from 'react-router-dom'
import Spinner from '../ui/spinner'

type Props = {
  onChange: (e: any) => void
  defaultValue?: string // client id selected by default
  required?: boolean
  disabled?: boolean
  hideNewLink?: boolean
  showAll?: boolean
  value?: string
  size?: 'md' | 'sm' | 'lg'
}

const ClientSelector: React.FC<Props> = ({ hideNewLink, showAll, defaultValue, onChange, value }) => {
  const { data, error, isLoading } = useSWR('/api/clients', getClients)
  const { addAlert } = useAlerts()

  if(isLoading) return <Spinner />

  if(error) {
    addAlert('error', error.message)
  }

  let _defaultValue = defaultValue
  if(data && data.length === 1 && !showAll) {
    // if there is only 1 client we select it by default
    _defaultValue = data[0]._id
  }
  let options: { label: string, value: string}[] = []
  if(data) {
    options = options.concat(data?.map((client: any) => ({
      label: client.tradingName,
      value: client._id
    })))
  }
  options.push({ label: 'Add New Client', value: 'to:/clients/new?redirectAfterSaveTo=/invoices/new' })
  return (
    <>
      <SelectBox id='client' placeHolder='' label='Client' name='clientId' value={value} defaultValue={_defaultValue as string} options={options} onChange={onChange} />
      {!hideNewLink && (
        <div className='mt-2'>
          <NavLink to='/clients/new?redirectAfterSaveTo=/invoices/new'>Add New Client</NavLink>
        </div>
      )}
    </>
  )
}

export default ClientSelector
