import { InvoiceItem } from "../types"

export const getFloatValueFromString = (stringValue = '') => {
  if(!stringValue || stringValue.trim() === '') {
    stringValue = '0'
  }
  const value = parseFloatValue(stringValue)
  return value
}

export const calculateTotal = (invoiceItems: InvoiceItem[], isTaxed: boolean) => {  
  const total = invoiceItems.reduce((prevValue, item) => {
    const amount = item.amount ? parseFloat(item.amount.toString()) : 0
    const taxAmount = item.taxPercentage ? amount * (parseFloat(item.taxPercentage.toString()) / 100) : 0

    return prevValue += (amount + (isTaxed ? taxAmount : 0))
  }, 0)
  return parseFloatValue(total)
}

export const calculateTax = (invoiceItems: InvoiceItem[]) => {  
  const totalTaxAmount = invoiceItems.reduce((prevValue, item) => {
    const amount = item.amount ? parseFloat(item.amount.toString()) : 0
    const taxAmount = item.taxPercentage ? amount * (parseFloat(item.taxPercentage.toString()) / 100) : 0

    return prevValue += taxAmount
  }, 0)
  return parseFloatValue(totalTaxAmount)
}

export const flattenObject = (obj: any, prefix = '') => {
  return Object.entries(obj).reduce((acc: any, [key, value]) => {
    const newKey = prefix ? `${prefix}.${key}` : key
    if (typeof value === 'object' && value !== null) {
      Object.assign(acc, flattenObject(value, newKey))
    } else {
      acc[newKey] = value
    }
    return acc
  }, {})
}

/**
 * Parses a string or number to a float value capped to 2 decimal places.
 */
export const parseFloatValue = (value: string | number | undefined) => {
  if(!value) return undefined
  let str = value.toString()
  if(str.indexOf('.') !== -1) {
    // capped to 2 decimal places
    str = str.slice(0, (str.indexOf('.')) + 3) 
  }
  return Number(str)
}

/**
 * Gets the last number in a given string.
 * If no nuber is found will return null
 * For example: itemInvoiceAmount99 will return 99
 * @param str 
 * @returns 
 */
export const getLastDigitOfString = (str: string): number | null => {
  const match = str.match(/\d+$/)
  if(match) {
    return parseInt(match[0])
  } else {
    return null
  }
}