import React from 'react'

import SideBar from '../../components/ui/layout/sidebar'
import GoBackButton from '../../components/ui/button/goBack'
import './styles.scss'

type Props = {
  children: React.ReactNode,
  header?: string,
  actions?: React.ReactElement,
  hideMenus?: boolean,
  backTo?: string
}
const MasterPage: React.FC<Props> = ({ children, header, hideMenus, actions, backTo }) => {
  const headerContent = <>
    <div className='d-none d-md-block'><GoBackButton backTo={backTo} /></div>
    <div className='w-100 d-flex flex-col align-items-center'>
      <h6 className='me-3 opacity-60'>{header}</h6>
    </div>
    <div>{actions}</div>
  </>
  return (
    <div>
      <SideBar hideMenus={hideMenus} headerContent={headerContent} />
      <div className='page-content bg-white'>
        <div className='content-header align-items-center d-md-flex d-inline-flex gap-3'>
          {headerContent}
        </div>
        <div className='content-body mb-5 mb-sm-0'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default MasterPage
