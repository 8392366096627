import React from 'react'
import useSWRMutation from 'swr/mutation'

import { playgroundCall } from '../../api'

const Playground = () => {
  const [apiPath, setApiPath] = React.useState<string>('')
  const { trigger, data, error, isMutating } = useSWRMutation('/api/user', playgroundCall)

  const callApi = async () => {
    trigger({ apiPath })
  }

  const onChangeApiUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const apiUrl = e.target.value
    setApiPath(apiUrl)
  }

  return (
    <div>
      <h1>Playground</h1>
      {/* show pretty json in text area */}
      <input defaultValue='/api/admin' type='text' onChange={onChangeApiUrl} />
      {isMutating ? <p>Loading...</p> : <button onClick={callApi}>Call API</button>}
      {error && <p>{error?.message}</p>}
      <textarea
        style={{ width: '100%', height: '400px' }}
        value={JSON.stringify(data, null, 2)}
      />
    </div>
  )
}

export default Playground
