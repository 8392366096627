import React from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import useSWRMutation from 'swr/mutation'
import useSWR from 'swr'

import { getEmailTemplate, getInvoiceById, sendInvoiceEmail } from '../../api'
import Spinner from '../../components/ui/spinner'
import Button, { ButtonSize, ButtonVariant } from '../../components/ui/button'
import Input from '../../components/ui/input'
import { InvoiceStatus } from '../../types'
import { useAlerts } from '../../context/alerts'
import TextArea from '../../components/ui/input/textArea'
import './styles.scss'

/**
 * Page to customise and confirm sending an email to a client.
 */
const SendEmailPage = () => {
  const navigate = useNavigate()
  const { addAlert } = useAlerts()

  // get invoice
  const { id } = useParams()
  const {  data: invoiceData, isLoading: isLoadingInvoice } = useSWR(id ? `/api/invoices/${id}`: null, getInvoiceById)
  const { data: emailTemplate, isLoading: isLoadingEmailTemplate } = useSWR(id ? `/api/invoices/email?invoiceId=${id}` : null, getEmailTemplate)
  const { data: emailReminderTemplate, isLoading: isLoadingEmailReminderTemplate } = useSWR(id ? `/api/invoices/email-reminder?invoiceId=${id}` : null, getEmailTemplate)
  // get email template
  // send email trigger
  const { trigger: triggerSendEmail, isMutating: isSending } = useSWRMutation('/api/invoices/email', sendInvoiceEmail)
  const { trigger: triggerSendReminderEmail, isMutating: isSendingReminder } = useSWRMutation('/api/invoices/email-reminder', sendInvoiceEmail)
  const [values, setValues] = React.useState<{ emailContent: string }>({ emailContent: '' })
  const handleBack = (e: any) => {
    e.preventDefault()
    navigate(-1)
  }

  const sendEmail = async (e: any) => {
    e.preventDefault()
    if(!triggerSendEmail || !triggerSendReminderEmail) throw new Error('triggerSendEmail is not defined')
    const overDueInvoice = invoiceData.status === InvoiceStatus.OVERDUE
    try {
      if(overDueInvoice) {
        await triggerSendReminderEmail({ body: { invoiceId: invoiceData._id, content: values.emailContent } })
      } else {
        await triggerSendEmail({ body: { invoiceId: invoiceData._id, content: values.emailContent } })
      }
      // await mutateCache(`/api/invoices/${invoiceData._id}`)
      addAlert({ type: 'info', message: 'Email will be sent soon' }) 
      navigate(-1)
    } catch (error: any) {
      console.error(error)
      addAlert({ type: 'error', message: error?.message || 'Error sending email. Please try later.' }) 
    }
  }
  
  if(isLoadingInvoice || isLoadingEmailTemplate || isLoadingEmailReminderTemplate) return <Spinner />
  if(isSending || isSendingReminder) {
    return (
      <div className='col-lg-6'>
        <b>Sending Email </b>
        <Spinner />
      </div>
    )
  }

  const handleChange = (e: any) => {
    e.preventDefault()
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const client = invoiceData?.client
  const { defaultText: defaultNewInvoiceText, seeInvoiceUrl: seeNewInvoiceUrl } = emailTemplate?.data || {}
  const { defaultText: defaultReminderText, seeInvoiceUrl: seeReminderInvoiceUrl } = emailReminderTemplate?.data || {}

  const invoiceOverdue = invoiceData.status === InvoiceStatus.OVERDUE
  let defaultText = defaultNewInvoiceText
  let seeInvoiceUrl = seeNewInvoiceUrl
  if(invoiceOverdue) {
    defaultText = defaultReminderText
    seeInvoiceUrl = seeReminderInvoiceUrl
  }
  return (
    <div className='col-lg-6'>
      <div>
        <Input label='Client Email' value={client?.email} readOnly />
        <div className='mt-1'><NavLink to={`/clients/${client?._id}`}>Edit</NavLink></div>
      </div>
      <div className='mt-4 mb-4'>
        <b>Email Content</b>
        <div className='mt-2 card p-2 '>
          <TextArea label='' name='emailContent' className='form-control card-text no-border' rows={6} defaultValue={defaultText} onChange={handleChange} />
          <div className='col-3 mt-3 mx-2'><Button><a href={seeInvoiceUrl} target='_blank' rel='noreferrer'>See Invoice</a></Button></div>
        </div>
      </div>
      <div className='d-flex justify-content-between'>
        <div>
          <Button size={ButtonSize.LARGE} variant={ButtonVariant.LIGHT} onClick={handleBack}>Back</Button>
        </div>
        <div>
          <Button size={ButtonSize.LARGE} onClick={sendEmail}>Send</Button>
        </div>
      </div>
    </div>
  )
}

export default SendEmailPage