import useSWR from 'swr'
import { Link } from 'react-router-dom'

import List from '../ui/list'
import { Client } from '../../types'
import { getClients } from '../../api'

const ClientsList = () => {
  const { data } = useSWR('/api/clients', getClients)
  
  const headers = [{ label: 'Trading Name' }, { label: 'Email' }]
  const rows: Array<any[]> = []
  if(data) {
    data.forEach((client: Client) => {
      rows.push([
        <Link to={`/clients/${client._id}`}>{client.tradingName}</Link>, 
        client.email
      ])
    })
  }
  
  return (
    <List headers={headers} rows={rows} />
  )
}

export default ClientsList