import { useNavigate } from 'react-router-dom'

import ClientForm from '../../components/clients/form'
import { useAlerts } from '../../context/alerts'
import useQuery from '../../context/useQuery'

type Props = {
  redirectAfterSaveTo?: string
}
const NewClient: React.FC<Props> = ({ redirectAfterSaveTo }) => {
  // get query param redirectAfterSaveTo
  const query = useQuery()
  const redirectAfterSaveToFromQuery = query.get('redirectAfterSaveTo')
  const { addAlert } = useAlerts()
  const navigate = useNavigate()

  const onSave = async (clientId: string) => {
    try {
      if(redirectAfterSaveTo) {
        // this is used when this component is used inline other pages
        return navigate(`${redirectAfterSaveTo}?clientId=${clientId}`)  
      } else if (redirectAfterSaveToFromQuery) {
        // this is used when the page is accessed directly passing the param redirectAfterSaveTo
        return navigate(`${redirectAfterSaveToFromQuery}?clientId=${clientId}`)  
      }
      // this is used when the page is accessed directly without passing the param redirectAfterSaveTo
      return navigate('/clients')
    } catch(error: any) {
      addAlert({ type: 'error', message: error?.message })
    }
  }

  const handleCancel = () => {
    return navigate(-1)
  }

  return (
    <div className='col-lg-6'>
      <ClientForm
        onSave={onSave} 
        onCancel={handleCancel}
      />
    </div>
  )
}

export default NewClient