import React from 'react'

const Terms = () => {
  return (
    <div>
      <h1>Privacy Terms | Tiny Invoice</h1>
      <p>
      Introduction

      Welcome to "Tiny Invoice" (the "Service"). These Terms and Conditions ("Terms") govern your use of the Service, and by using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Service.

      User Obligations By using the Service, you represent and warrant that:

      You are at least 18 years of age or the legal age of majority in your jurisdiction, whichever is higher; You will not use the Service for any illegal purpose; You will not use the Service to infringe any intellectual property rights or other proprietary rights of any third party; You will not use the Service to harm, harass, or otherwise interfere with the rights of others. Intellectual Property The Service, including its design, code, and content, is protected by copyright, trademark, and other intellectual property laws. You acknowledge and agree that all intellectual property rights in and to the Service are owned by us or our licensors.

      Privacy Policy Our Privacy Policy sets out the terms on which we process any personal data we collect from you or that you provide to us. By using the Service, you consent to such processing and you warrant that all data provided by you is accurate.

      Disclaimer of Warranties The Service is provided "as is" and without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that the Service will be uninterrupted or error-free.

      Limitation of Liability In no event shall we be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use of the Service, whether based on contract, tort, strict liability, or otherwise, even if we have been advised of the possibility of such damages.

      Indemnification You agree to indemnify, defend, and hold harmless us, our affiliates, and our respective officers, directors, employees, agents, licensors, and suppliers from and against all claims, losses, liabilities, expenses, damages, and costs, including reasonable attorneys fees, arising out of or in connection with your use of the Service or your breach of these Terms.

      Governing Law These Terms and your use of the Service shall be governed by and construed in accordance with the laws of Spain.

      Changes to the Terms We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.

      Termination We may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms. Upon termination, your right to use the Service will immediately cease.

      Entire Agreement These Terms constitute the entire agreement between you and us regarding the use of the Service and supersede all prior agreements and understandings, whether written or oral, between you and us.

      If you have any questions about these Terms, please contact us at nicofetter@gmail.com.
      </p>
    </div>
  )
}

export default Terms