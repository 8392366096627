import React from 'react'

import Input from '../ui/input'
import Button, { ButtonVariant } from '../ui/button'
import { Currency, InvoiceItem } from '../../types'
import IconButton from '../ui/button/icon'
import { ReactComponent as TrashIcon } from '../../static/images/trash.svg'
import './invoiceItemsStyles.scss'

type Props = {
  onItemChange: (invoiceItems: any[]) => void
  addNewItem: () => void
  removeItem: (index: number) => void
  values: {
    items: InvoiceItem[]
    total: string | number | undefined
    totalTax: string | number | undefined
  }
  taxLabel?: string
  currency?: Currency 
  enableTax?: boolean
  disabled?: boolean
}

const InvoiceItems: React.FC<Props> = ({ disabled, enableTax, taxLabel, values, currency, addNewItem, removeItem, onItemChange }) => {
  const onAddNewItem = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    addNewItem()
  }
  const onRemoveItem = (e: any, index: number) => {
    e.preventDefault()
    e.stopPropagation()
    removeItem(index)
  }
  return (
    <div>
      <div>
          <div className='row py-2'>
            <div className='col'><b className='form-label'>Description</b></div>
            <div className='col-3 col-sm-2'><b className='form-label'>Amount <small>{currency && `(${currency.code} | ${currency.symbol})`}</small></b></div>
            {enableTax && <div className='col-sm-1 g-0'><b className='form-label'>{taxLabel} (%)</b></div>}
            <div className='col-1' />
          </div>
          {values.items && values.items.length > 0 && values.items.map((item, index) => (
            <div className='row py-1' key={index} id={`invoice-item-${index}`}>
              <div className='col'>
                <Input 
                  type='text'
                  autoComplete='off' 
                  value={item.description} 
                  name={`invoiceItemDescription${index}`} 
                  onChange={onItemChange}
                  disabled={disabled}
                />
              </div>
              <div className='col-3 col-sm-2'>
                <Input 
                  type='number'  
                  step='0.01' 
                  value={item.amount}
                  name={`invoiceItemAmount${index}`} 
                  onChange={onItemChange}
                  disabled={disabled}
                />
              </div>
              {enableTax && (
                <div className='col-1 g-0'>
                  <Input 
                    type='number'  
                    step='1' 
                    // parse to float the default value
                    defaultValue={item.taxPercentage && parseFloat(item.taxPercentage.toString())}
                    value={item.taxPercentage && parseFloat(item.taxPercentage.toString())}
                    name={`invoiceItemTaxPercentage${index}`} 
                    onChange={onItemChange}
                    disabled={disabled}
                  />
                </div>
              )}
              <div className='col-1 w-1 p-0 ml-auto d-flex align-items-center'>
                {!disabled && (
                  <IconButton icon={<TrashIcon height={30} width={30} />} onClick={(e: any) => onRemoveItem(e, index)} />
                )}
              </div>
            </div>
          ))}
          <ItemActions onAddNewItem={onAddNewItem} disabled={disabled} />
      </div>
    </div>
  )
}

type ActionProps = {
  onAddNewItem: (e: any) => void
  disabled?: boolean
}
const ItemActions: React.FC<ActionProps> = ({ onAddNewItem, disabled }) => {
  return (
    <div className='row'>
      <div className='col col-lg-3'>
        <Button variant={ButtonVariant.LIGHT} type='button' onClick={onAddNewItem} disabled={disabled}>Add new item</Button>
      </div>
    </div>
  )
}

export default InvoiceItems