export type Alert = {
  id: number
  type: 'info' | 'error' | 'warning'
  message: string
}


/** Backend Types */
/** TODO: find a way to share between projects */

export enum InvoiceStatus {
  OPEN = 'open',
  PAID = 'paid',
  OVERDUE = 'overdue',
}

export enum InvoiceActivity {
  CREATED= 'Created',
  EMAIL_ON_QUEUE = 'Sending Email',
  EMAIL_SENT = 'Email Sent',
  EMAIL_FAILED = 'Email Failed' ,
  EMAIL_VIEWED = 'Viewed',
}

export type TTax = {
  name: string
  percentage: number
}

export type TBusiness = {
  name: string
  email: string
  enableTax: boolean
  billingAddress?: string,
  paymentInstructions?: string
  tax: TTax
  currency: Currency
}

export type Invoice = {
  _id?: string
  items: InvoiceItem[]
  userId?: string
  clientId: string
  lastActivity?: InvoiceActivity
  isDeleted?: boolean
  total?: number | string
  totalTax?: number | string
  code?: string
  status?: InvoiceStatus
  activity?: InvoiceActivity
  client?: Client // populated
  issueDate: Date
  dueDate: Date
  locked: boolean // updates to form are not possible
  businessDetails?: TBusiness
}

export type TInvoiceForm = {
  _id?: string
  code?: string
  status?: InvoiceStatus
  items: InvoiceItem[]
  clientId?: string
  issueDate: Date
  dueDate: Date
  locked: boolean
  businessDetails: TBusiness
  total?: number | string
  totalTax?: number | string
}

export type TFormError = {
  type: string
  message: string
}

export type InvoiceItem = {
  description?: string
  amount?: string | number
  taxPercentage?: string | number
}

export type Client = {
  _id?: string
  tradingName: string
  name?: string
  lastName?: string
  billingAddress: string
  email: string
  userId?: string
  isDeleted?: boolean
}
export type TClientForm = {
  _id?: string
  'client.tradingName': string
  'client.billingAddress': string
  'client.name'?: string
  'client.lastName'?: string
  'client.email': string
}


export type Currency = {
  _id: string
  name: string
  code: string
  symbol: string
}

export type TReminder = {
  days: number // amount of days after due date to send reminder
}

export type TUserSettings = {
  reminders: [TReminder]
}
