import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='container text-center py-4'>
      <hr />
      <div className='row mb-2'>
        <b>Made with ❤️ by <a href='https://x.com/nicofetter' target='_blank'>@nicofetter</a></b><small className='mt-1 text-dark'>DM on Twitter for feature requests</small>
      </div>
      <div>
        <Link to='https://tinyinvoice.app/terms' className='col-1 mx-2'><small>Terms</small></Link>
        <Link to='/privacy' className='col-1 mx-2 ms-2'><small>Privacy</small></Link>
      </div>
    </div>
  )
}

export default Footer
