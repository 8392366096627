import React from 'react'

import { InvoiceStatus } from '../../types'
import SelectBox from '../ui/selectBox'

type Props = {
  [x:string]: any
  defaultValue?: string | string[] | undefined
  showAll?: boolean
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}
const StatusSelector: React.FC<Props> = ({ showAll, defaultValue, onChange }) => {
  const options = [{
    value: InvoiceStatus.OPEN,
    label: 'Open'
  }, {
    value: InvoiceStatus.OVERDUE,
    label: 'Overdue'
  }, {
    value: InvoiceStatus.PAID,
    label: 'Paid'
  }]


  return (
    <SelectBox label='Invoice Status' placeHolder='' name='status' onChange={onChange} placeholder={'Invoice Status'} options={options} isMulti={showAll} defaultValue={defaultValue} />
  )
}

export default StatusSelector
