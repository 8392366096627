import useSWR from 'swr'
import { useParams } from 'react-router-dom'

import { getClientBalance } from '../../api'
import InvoiceSimpleList from '../invoices/simpleList'
import { Invoice } from '../../types'
import Amount from '../ui/currency/amount'

const ClientBalance = () => {
  const { id } = useParams()
  const { data, error, isLoading } = useSWR(`/api/clients/${id}/balance`, getClientBalance)

  if(isLoading) return <div>Loading...</div>
  if(!data || error){
    return <div>Error ocurred.</div>
  }

  if(data.invoices.length === 0) {
    return <div>No unpaid invoices</div>
  }

  return (
    <div>
      <h3>Unpaid Invoices</h3>
      <div className='mt-2' />
      <div>
        <InvoiceSimpleList invoices={data.invoices as Invoice[]} />
      </div>
      <div className='total-due p-4 mt-4 mb-4 bg-primary'>
        <h3 className='text-white'>
          <span className='small'>TOTAL DUE </span>
          <span className='display-5'> {data.invoices[0]?.businessDetails?.currency?.symbol}</span>
          <span className='display-5'><Amount currencySymbol={data.invoices[0]?.businessDetails?.currency?.code} value={data.total} /></span>
        </h3>
      </div>
    </div>
  )
}

export default ClientBalance
