import React from 'react'
import useSWRMutation from 'swr/mutation'
import { useNavigate } from 'react-router-dom'

import Input from '../ui/input'
import { useAuth } from '../../context/auth'
import Button, { ButtonSize, ButtonVariant } from '../ui/button'
import { updateReminders } from '../../api'
import { useAlerts } from '../../context/alerts'
import IconButton from '../ui/button/icon'
import { ReactComponent as TrashIcon } from '../../static/images/trash.svg'
import './styles.scss'

const RemindersForm = () => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { addAlert } = useAlerts()
  const { trigger: triggerUpdate, isMutating: isLoading } = useSWRMutation('/api/user/reminders', updateReminders)
  const [pristine, setPristine] = React.useState(true)
  const [values, setValues] = React.useState<Array<{ daysAfterDue: number }>>(user?.reminders || [])

  if(!user) return null
  const onChangeValues = (newValues: any) => {
    setValues(newValues)
    setPristine(false)
  }
  
  const addNewReminder = () => {
    const newValues = [...values, { daysAfterDue: 1 }]
    onChangeValues(newValues)
  }

  const handleChange = (e: any, index: number) => {
    const { value } = e.target
    const newValues = [...values]
    newValues[index].daysAfterDue = parseInt(value)
    onChangeValues(newValues)
  }

  const removeReminder = (index: number) => {
    const newValues = [...values]
    newValues.splice(index, 1)
    onChangeValues(newValues)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setPristine(true)
    triggerUpdate({ body: { reminders: values } })
    addAlert({ type: 'success', message: 'Email reminders updated' })
  }

  const handleCancel = (e: any) => {
    e.preventDefault()
    navigate(-1)
  }

  return (
    <form onSubmit={handleSubmit}>
      {values?.map((reminder, index) => (
        <div className='row d-flex align-items-center mb-3'>
          <div className='col small-input'><Input type='number' min={1} max={30} key={index} value={reminder.daysAfterDue} onChange={(e: any) => handleChange(e, index)} /></div>
          <div className='col-3'>{`day${reminder.daysAfterDue > 1 ? 's' : ''} after due date`}</div>
          <div className='col-1'>
            <IconButton icon={<TrashIcon height={30} width={30} />} onClick={() => removeReminder(index)} />
          </div>
        </div>
      ))}
      <Button 
        type='button'
        variant={ButtonVariant.LIGHT} 
        size={ButtonSize.DEFAULT} 
        onClick={addNewReminder}
        disabled={values && values.length >= 2}
      >
        Add reminder
      </Button>
      <div className='d-flex mt-5 justify-content-between'>
        <Button 
          variant={ButtonVariant.LIGHT}
          size={ButtonSize.LARGE} 
          onClick={handleCancel}
          loading={isLoading}
        >
          Back
        </Button>
        <Button 
          size={ButtonSize.LARGE} 
          disabled={pristine}
          type='submit'
          loading={isLoading}
        >
          Save
        </Button>
      </div>
    </form>
  )
}

export default RemindersForm