import React from 'react'
import config from '../../config';

declare global {
  interface Window {
    chatwootSettings: {
      hideMessageBubble: boolean;
      position: string;
      type: string;
    };
    chatwootSDK: {
      run: (config: { websiteToken: string; baseUrl: string }) => void;
    };
  }
}

const Chatwoot = () => {
  React.useEffect(() => {
    ((d, t) => {
      window.chatwootSettings = {
        hideMessageBubble: false,
        position: 'right', // This can be left or rightlocale: "en",
        type: 'standard', // [standard, expanded_bubble]
      }
      const BASE_URL = 'https://app.chatwoot.com'
      const g: any = d.createElement(t)
      const s: any = d.getElementsByTagName(t)[0]
      g.src = BASE_URL + '/packs/js/sdk.js'
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: config.REACT_APP_CHATWOOT_TOKEN,
          baseUrl: BASE_URL,
        });
      };
    })(document, 'script')

  }, [])

  return null
}

export default Chatwoot
