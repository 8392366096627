import { Link } from 'react-router-dom'

import UsersList from '../../components/users/list'

const AdminPanel = () => {
  return (
    <div>
      <Link to='/admin/playground'>Playground</Link>
      <br />
      <UsersList />
    </div>
  )
}

export default AdminPanel
