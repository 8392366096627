import React from 'react'
import Select, { type Options, type SingleValue } from 'react-select'
import { useNavigate } from 'react-router-dom'

type Option = { label: string, value: string }
type Props = {
  label?: string
  size?: 'md' | 'sm' | 'lg'
  onChange: (e: any) => void
  options: Option[]
  defaultValue?: string | string[]
  isMulti?: boolean
  id?: string
  name: string
  placeHolder?: string
  [x:string]: any
}

const SelectBox: React.FC<Props> = ({ placeHolder, name, id, size='md', label, onChange, options, isMulti, value, defaultValue }) => {
  const navigate = useNavigate()

  const handleChange = (value: Options<Option> | SingleValue<Option>) => {
    if(Array.isArray(value)) {
      onChange({ target: { name, value: value.map(v => v.value).join(',') } } as any)
    } else if ((value as Option)?.value.startsWith('to:')) {
      return navigate((value as Option)?.value.replace('to:', ''))
    } else {
      return onChange({ target: { name, value: (value as Option)?.value } } as any)
    }
  }

  const getValue = (v: string | string[] | undefined): SingleValue<Option> | Options<Option> | undefined => {
    if(!v) return undefined
    if(Array.isArray(v)) {
      return options.filter(o => v.includes(o.value))
    }
    return options?.find(o => o.value === v) as SingleValue<Option>
  }

  const selectTheme = (theme: any) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary: '#0A146B',
      primary25: '#B7C6DA',
    },
  })
  const styles = {
    control: (baseStyles:any, state: any) => ({
      ...baseStyles,
      borderRadius: '5px'
    }),
  }
  return (
    <>
      {label && <label htmlFor={id} className={`form-label ${size === 'md' ? 'bold-label' : 'light-label'}`}>{label}</label>}
      <Select placeholder={placeHolder} styles={styles} theme={selectTheme} options={options} isMulti={isMulti} onChange={handleChange} value={getValue(value)} defaultValue={getValue(defaultValue)} />
    </>
  )
}

export default SelectBox
