import React from 'react'
import useSWRImmutable from 'swr/immutable'

import SelectBox from '../ui/selectBox'
import { getCurrencies } from '../../api'
import Spinner from '../ui/spinner'

type Props = {
  onChange: (e: any) => void,
  defaultValue?: string
  disabled?: boolean
}

const CurrencySelector: React.FC<Props> = ({ defaultValue, onChange }) => {
  const { data: currenciesData, isLoading } = useSWRImmutable('/api/invoices/currencies', getCurrencies)
  const options = currenciesData?.map((currency: any) => ({
    label: `${currency.name} | ${currency.symbol}`,
    value: currency.code
  }))
  if(isLoading) return <Spinner />
  return (
    <SelectBox
      label='Currency'
      id='currencyCode'
      name='businessDetails.currency.code'
      options={options}
      defaultValue={(defaultValue as string)}
      onChange={onChange}
    />
  )
}

export default CurrencySelector
