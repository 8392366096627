import React from 'react'

interface AuthContextType {
  user: any
  signin: () => void
  signout: () => void
  mutate: () => void
}

let AuthContext = React.createContext<AuthContextType>(null!)

function useAuth() {
  return React.useContext(AuthContext)
}

export {
  AuthContext,
  useAuth
}