import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import useSWRImmutable from 'swr/immutable'

import ClientSelector from '../clients/selector'
import InvoiceItems from './invoiceItems'
import { useAlerts } from '../../context/alerts'
import Input from '../ui/input'
import Button, { ButtonSize, ButtonVariant } from '../ui/button'
import { useAuth } from '../../context/auth'
import * as utils from '../../utils'
import { InvoiceStatus, TFormError, TInvoiceForm } from '../../types'
import StatusSelector from './statusSelector'
import TextArea from '../ui/input/textArea'
import { getCurrencies } from '../../api'
import Spinner from '../ui/spinner'
import './styles.scss'
import config from '../../config'

export const EMPTY_ITEM = { description: '', amount: '', taxPercentage: '' }

export const validateForm = (formValues: TInvoiceForm): Array<TFormError> => {
  const allItemsAreEmpty = formValues.items.every(item => item.amount === '' && item.description === '')

  const errors = []

  if(!formValues.code || formValues.code.trim() === '') {
    errors.push({ type: 'error', message: 'Please set the invoice reference' })
  } else if(formValues.items.length === 0) {
    errors.push({ type: 'error', message: 'Please add at least one item' })
  } else if(allItemsAreEmpty) {
    errors.push({ type: 'error', message: 'Please add at least one item' })
  } else if (
      formValues.items.find(item => item.amount !== '' && item.description?.trim() === '')
      || formValues.items.find(item => item.amount === '' && item.description?.trim() !== '')
    ){
      errors.push({ type: 'error', message: 'Please add description and amount for each item' })
  } else if(new Date(formValues.issueDate) > new Date(formValues.dueDate)) {
    errors.push({ type: 'error', message: 'Due Date can not be before Issue Date' })
  } else if (formValues?.businessDetails?.enableTax && !formValues?.businessDetails?.tax?.name) {
    errors.push({ type: 'error', message: 'Please complete your tax information' })
  }
  return errors
}

type Props = {
  id?: string,
  triggerSaveInvoice: (e: any) => void,
  triggerRemoveInvoice?: (e: any) => void,
  isLoading: boolean,
  defaultValues: TInvoiceForm,
}

const InvoiceForm: React.FC<Props> = ({ defaultValues, triggerSaveInvoice, triggerRemoveInvoice, isLoading }) => {
  // routing
  const navigate = useNavigate()
  // form management
  const [values, setValues] = React.useState(defaultValues)

  const [isPristine, setPristine] = React.useState(!values._id ? false : true)
  // data
  const { data: currenciesData } = useSWRImmutable('/api/invoices/currencies', getCurrencies)
  const { mutate: mutateCache } = useSWRConfig()
  const { user } = useAuth()
  // alerts
  const { addAlert } = useAlerts()

  const handleValuesChange = (newValues: any) => {
    setValues(newValues)
  }
  // event handlers
  const handleChange = (event: any) => {
    const auxValues: any = { ...values }
    auxValues[event.target.name] = event.target.value
    if(event.target.name === 'issueDate') {
      // add 7 days to due date
      auxValues['dueDate'] = new Date().setDate(new Date(event.target.value).getDate() + 7)
    }
    setPristine(false)
    handleValuesChange(auxValues)
  }

  const handleSubmit = async(e: any, silent?: boolean) => {
    e.preventDefault()
    try {
      const errors = validateForm(values)
      if(errors.length > 0) {
        errors.forEach(error => addAlert(error))
        return false
      }
      const response: any = await triggerSaveInvoice({ body: values })
      if(values._id) {
        mutateCache(`/api/invoices${values._id ? `/${values._id}` : ''}`)
      } else {
        mutateCache(`/api/invoices`)
        // redirect to edit page
        navigate(`/invoices/${response.data.insertedId}`)
      }
      if(!silent) {
        addAlert({ type: 'info', message: 'Invoice saved successfully' })
      }
      setPristine(true)
    } catch(error) {
      // do nothing. Global swrConfig handles this
    }
  }

  const downloadPdf = async (e: any) => {
    await handleSubmit(e, true)
    window.open(`${config.REACT_APP_API_URL}/api/invoices/pdf/${values._id}`, '_blank')
  }

  const getCurrencyByCode = (currencyCode: string) => {
    return currenciesData?.find((currency: any) => currency.code === currencyCode)
  }

  const onAddNewItem = () => {
    handleValuesChange({ ...values, ...{ items: [...values.items, { ...EMPTY_ITEM, taxPercentage: user.businessDetails.tax.percentage }] } })
  }

  const onRemoveItem = (index: number) => {
    if(values.items.length === 1) {
      addAlert({ type: 'error', message: 'You must have at least 1 item' })
      return
    }
    const newItems = [...values.items]
    newItems.splice(index, 1)
    handleValuesChange({ ...values, ...{ items: newItems } })
  }

  const onItemChange = (e: any) => {
    const { name, value } = e.target
    const newItems = [...values.items]
    const index = utils.getLastDigitOfString(name)
    let attribute: string = ''
    if(name.startsWith('invoiceItemDescription')) {
      attribute = 'description'
    } else if(name.startsWith('invoiceItemAmount')) {
      attribute = 'amount'
    } else if(name.startsWith('invoiceItemTaxPercentage')) {
      attribute = 'taxPercentage'
    }
    if(index !== null) {
      const item = newItems[index]

      const newITems = [...values.items]

      newITems[index] = { ...item, [attribute]: value }
      setPristine(false)
      handleValuesChange({
        ...values,
        total: utils.calculateTotal(newITems, values?.businessDetails?.enableTax || false),
        totalTax: values?.businessDetails?.enableTax ? utils.calculateTax(newITems) : 0,
        items: newITems
      })
    }
  }

  const isSaveDisabled = () => {
    if(isPristine) {
      return true
    }
    if(defaultValues.locked && values.status === defaultValues.status) {
      return true
    }
    return false
  }

  const cloneAndCreateNew = () => {
    navigate(`/invoices/new?cloneId=${values._id}`)
  }

  const handleCancel = () => {
    navigate('/dashboard')
  }

  const isNewInvoice = !values._id

  if(isLoading) {
    return <Spinner />
  }

  return (
    <div className="col py-5 d-flex align-items-center justify-content-center">
      <form onSubmit={handleSubmit} className='card border-3 p-4 bg-white form-container'>
        <div className='row py-2'>
          <div className='col col-lg-4'>
            <ClientSelector value={values?.clientId} onChange={handleChange} required disabled={defaultValues.locked} />
          </div>
          <div className='col-12 col-lg-5 ms-auto text-end'>
            <div><p className='fs-2 m-0 fw-light'>{user.businessDetails.name}</p></div>
            <div className='fs-5 fw-light'><small>{user.businessDetails.email}</small></div>
            <div className='mt-2'><NavLink to='/profile'>Edit</NavLink></div>
          </div>
        </div>
        <div className='row py-3'>
          <div className='col-lg-3 mt-lg-0 mt-2'>
            <Input
              label='Invoice Ref #'
              tooltip='A unique value to identify this invoice.'
              type='text'
              name='code'
              id='code'
              onChange={handleChange}
              defaultValue={defaultValues.code}
              value={values.code}
              required
              disabled={defaultValues.locked}
            />
          </div>
          <div className='col-lg-3 mt-lg-0 mt-2'>
            <Input
              label='Issue Date'
              type='date'
              name='issueDate'
              id='issueDate'
              onChange={handleChange}
              defaultValue={new Date(defaultValues.issueDate)?.toLocaleDateString('en-CA')}
              value={new Date(values.issueDate).toLocaleDateString('en-CA')}
              required
              disabled={defaultValues.locked}
            />
          </div>
          <div className='col-lg-3 mt-lg-0 mt-2'>
            <Input
              label='Due Date'
              type='date'
              name='dueDate'
              id='dueDate'
              onChange={handleChange}
              defaultValue={new Date(defaultValues.dueDate)?.toLocaleDateString('en-CA')}
              value={new Date(values.dueDate).toLocaleDateString('en-CA')}
              required
              disabled={defaultValues.locked}
            />
          </div>
          {!isNewInvoice && (
            <div className='col-lg-3 mt-lg-0 mt-2'>
              <StatusSelector label='Status' name='status' id='status' onChange={handleChange} defaultValue={values.status} size='md' />
            </div>
          )}
        </div>

        <div className='bottom-border p-2' />

        <div className='row py-3 bottom-border'>
          <InvoiceItems
            addNewItem={onAddNewItem}
            removeItem={onRemoveItem}
            onItemChange={onItemChange}

            values={{ items: values.items, total: values?.total, totalTax: values?.totalTax }}
            currency={values?.businessDetails?.currency.code && getCurrencyByCode(values?.businessDetails?.currency.code)}
            taxLabel={values?.businessDetails?.enableTax ? user?.businessDetails?.tax?.name : 'Tax Name Not Found'}
            enableTax={values?.businessDetails?.enableTax}
            disabled={defaultValues.locked}
          />
          {values?.businessDetails?.enableTax && !values?.businessDetails?.tax?.name ? null : (
            <InvoiceSummary total={values.total} isTaxed={values?.businessDetails?.enableTax || false} taxLabel={values?.businessDetails?.tax?.name} totalTax={values.totalTax} />
          )}

        </div>
        <div className='row py-4'>
          <div className='col'>
            <TextArea
              rows={4}
              label='Payment Instructions'
              disabled
              value={values?.businessDetails?.paymentInstructions}
            />
            <div className='mt-1'><NavLink to='/profile'>Edit</NavLink></div>
          </div>
        </div>
        <div className='d-flex py-3 row align-items-end gx-2 flex-row-reverse'>
          <div className='col-auto'>
            <Button
              type='submit'
              loading={isLoading}
              loadingText='Saving...'
              size={ButtonSize.LARGE}
              disabled={isSaveDisabled()}
            >
              Save
            </Button>
          </div>
          <div className='col-auto'>
            <Button
              type='button'
              size={ButtonSize.LARGE}
              variant={ButtonVariant.LIGHT}
              disabled={isLoading}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
          <div className='col d-lg-block d-none' />
          <div className='w-100 d-lg-none d-block my-4'></div>
          {!isNewInvoice && (
            <div className='col-auto mr-2'>
              <Button type='button' onClick={downloadPdf} size={ButtonSize.DEFAULT} variant={ButtonVariant.LIGHT} disabled={isLoading}>View PDF</Button>
            </div>
          )}
          {!isNewInvoice && (
            <div className='col-auto mr-2'>
              <Button type='button' disabled={isLoading} size={ButtonSize.DEFAULT} variant={ButtonVariant.LIGHT}>
                <NavLink to={`/invoices/${values._id}/send-email`}>{values.status === InvoiceStatus.OVERDUE ? 'Send Email Reminder' : 'Send Email'}</NavLink>
              </Button>
            </div>
          )}
          {!isNewInvoice && triggerRemoveInvoice && (
            <div className='col-auto mr-2'>
              <Button type='button' onClick={triggerRemoveInvoice} size={ButtonSize.DEFAULT} variant={ButtonVariant.LIGHT} disabled={isLoading}>Delete</Button>
            </div>
          )}
          {!isNewInvoice && (
            <div className='col-auto mr-2'>
              <Button type='button' onClick={cloneAndCreateNew} size={ButtonSize.DEFAULT} variant={ButtonVariant.LIGHT} disabled={isLoading}>Duplicate</Button>
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

type SummaryProps = {
  total: number | string | undefined,
  taxLabel?: string | undefined,
  totalTax: number | string | undefined,

  isTaxed: boolean
}

const InvoiceSummary: React.FC<SummaryProps> = ({ isTaxed, total, taxLabel, totalTax }) => {

  const renderSummaryItem = ({ label, name, value, link }: { link: React.ReactNode | undefined, label: string, name: string, value: string | number | undefined }) => {
    return (
      <div className='row py-1 d-flex align-items-center'>
        <div className='col' />
        <div className='col text-end'>
          <b>{label}</b>
          {link}
        </div>
        <div className='col col-4 col-sm-2'>
          <Input
            type='number'
            name={name}
            value={value}
            disabled
          />
        </div>
        <div className='col-1' />
      </div>
    )
  }
  return (
    <div>
      {isTaxed && taxLabel && renderSummaryItem({
        label: 'Subtotal',
        name: 'totalTax',
        value: utils.parseFloatValue(parseFloat(total?.toString() || '0') - parseFloat(totalTax?.toString() || '0')),
        link: undefined,
      })}
      {isTaxed && taxLabel && renderSummaryItem({
        label: taxLabel || '',
        name: 'totalTax',
        value: totalTax,
        link: <div className='mt-1'><NavLink to='/profile'>Edit</NavLink></div>,
      })}
      {renderSummaryItem({
        label: 'Total',
        name: 'total',
        value: total,
        link: undefined,
      })}
    </div>
  )
}

export default InvoiceForm
