import React from 'react'

const Privacy = () => {
  return (
    <div className='container'>
      <p><b>Privacy Policy for Tiny Invoice</b></p>
      <p>
        Effective Date: 13/01/2024

        Thank you for using Tiny Invoice ("the Tool"). This Privacy Policy explains how we collect, use, store, and share information when you use our services, particularly when you register using your Google account.

        Information We Collect
        When you register for Tiny Invoice using your Google account, we collect the following information:

        Name: We use your name to personalize your profile on our tool.
        Email: Your email is used for identification and communication purposes.
        How We Use Your Information
        We use the collected information solely for the purpose of enhancing your experience with Tiny Invoice. Specifically, we use your name to personalize your profile, and your email for identification and communication.

        How We Store Your Information
        Your information is securely stored on our servers. We have implemented reasonable measures to protect the confidentiality and security of your personal data.

        How We Share Your Information
        We do not share your information with third parties. Your data is kept confidential and is used exclusively for the purposes outlined in this Privacy Policy.

        Google User Data
        Tiny Invoice accesses and uses Google user data (name and email) only for the purposes stated in this Privacy Policy. We do not store or share any additional Google user data.

        Your Choices
        You can control the information you provide to Tiny Invoice by managing your Google account privacy settings.

        Updates to This Privacy Policy
        We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this Privacy Policy periodically.

        Contact Us
        If you have any questions or concerns about this Privacy Policy, please contact us at nicofetter@gmail.com.

        By using Tiny Invoice, you agree to the terms outlined in this Privacy Policy.
      </p>
    </div>
  )
}

export default Privacy