import { NavLink } from 'react-router-dom'

import Button, { ButtonSize, ButtonVariant } from '../../components/ui/button'
import Step from '../../components/ui/stepper/step'
import FakeInvoice from '../../components/ui/fakeInvoice'
import { useAuth } from '../../context/auth'
import { getClients } from '../../api'
import useSWR from 'swr'

const Step3Final = () => {
  const { user } = useAuth()
  const { data: clients } = useSWR('/api/clients', getClients)
  const client = clients && clients.length > 0 ? clients[0] : null
  return (
    <Step subHeading='You are ready to create your first invoice.' current={3} total={3}>
      <div className='row'>
        <div className='col-12 col-lg-4'>
          <div className='d-flex justify-content-between mt-5'>
            <Button variant={ButtonVariant.LIGHT} size={ButtonSize.LARGE}><NavLink to='/onboard-step-2'>Back</NavLink></Button>
            <Button size={ButtonSize.LARGE}><NavLink to='/invoices/new'>Create Your First Invoice</NavLink></Button>
          </div>
          <div className='text-end mt-2'>
            <NavLink to='/dashboard' className='btn btn-link text-muted'>
              I'll create my first invoice later
            </NavLink>
          </div>
        </div>
        <div className='col-lg-8'>
          <FakeInvoice
            businessName={user?.businessDetails?.name}
            businessAddress={user?.businessDetails?.billingAddress}
            businessEmail={user?.businessDetails?.email}
            paymentInstructions={user?.businessDetails?.paymentInstructions}
            clientName={client?.tradingName}
            clientAddress={client?.billingAddress}
            clientEmail={client?.email}
          />
        </div>
      </div>
    </Step>
  )
}

export default Step3Final
