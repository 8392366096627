import React from 'react'

import { InvoiceActivity } from '../../types'

type Props = {
  activity?: InvoiceActivity
}
const ActivityBadge: React.FC<Props> = ({ activity }) => {
  if(!activity) {
    return null
  }

  let className
  switch(activity) {
    case InvoiceActivity.CREATED:
      className = 'text-center'
      break
    case InvoiceActivity.EMAIL_ON_QUEUE:
      className = 'badge bg-light text-dark'
      break
    case InvoiceActivity.EMAIL_SENT:
      className = 'badge bg-info'
      break
    case InvoiceActivity.EMAIL_VIEWED:
      className = 'badge bg-success'
      break
    case InvoiceActivity.EMAIL_FAILED:
      className = 'badge bg-danger'
      break
    default:
      className = 'badge bg-info'
  }
  return <div className={className}>{InvoiceActivity.CREATED === activity ? '-' : activity}</div>
}

export default ActivityBadge
