import React from 'react'

import * as numberLib from '../../../lib/number'

/**
 * Use this everywhere you need to display a cost value with a currency symbol and 2 decimals.
 * @param value A cost value to be displayed with a currency symbol and 2 decimals.
 * @returns
 */
const Amount: React.FC<{ value: number | string, currencySymbol?: string }> = ({ currencySymbol, value }) => {
  // parse to number if string
  let parsedAmount = value as number
  if(typeof value === 'string') {
    parsedAmount = parseFloat(value) as number
  }
  return <span>{`${currencySymbol ?? '$'} ${numberLib.formatTo2Decimals(parsedAmount)}`}</span>
}

export default Amount
